import { React, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Home from "./pages/Home";
import Products from "./pages/Products";
import Services from "./pages/Services";
import Benefits from "./pages/Benefits";
import UseCases from "./pages/UseCases";
import Contact from "./pages/Contact";
import ComponentsPage from "./pages/Components";
// import NotFound from "./pages/NotFound";
import ProductDetail from "./pages/ProductDetail";
import NavHeader from "./components/nav-header";
import NavFooter from "./components/nav-footer";
import fetchOptions from "./api-options";

const App = () => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchOptions()
      .then((data) => {
        setOptions(data);
        setLoading(false);
      })
      .catch(() => {
        console.warn("no navigation available");
        setLoading(false);
      });
    setLoading(false);
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <>
              <NavHeader options={options} />
              <article className="main" role="main">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/products" element={<ComponentsPage />} />
                  <Route path="/services" element={<ComponentsPage />} />
                  <Route path="/benefits" element={<ComponentsPage />} />
                  <Route path="/use-cases" element={<ComponentsPage />} />
                  <Route path="/contact" element={<ComponentsPage />} />
                  <Route path="/about-us" element={<ComponentsPage />} />
                  <Route path="/product/*" element={<ComponentsPage />} />
                  <Route path="*" element={<ComponentsPage />} />
                </Routes>
              </article>
              <NavFooter options={options} />
            </>
          )}
        </>
      </Router>
    </HelmetProvider>
  );
};

export default App;
