import config from "./config";

const fetchOptions = async () => {
  try {
    const response = await fetch(
      `${config.apiBaseUrl}/api/navigations/1?populate[pages]=populate`
    );
    if (!response.ok) {
      throw new Error("Content not found");
    }
    const data = await response.json();
    console.log("rest api options", data);
    return data;
  } catch (error) {
    console.error("Error fetching content:", error);
    throw error;
  }
};

export default fetchOptions;
