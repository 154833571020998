import config from "../config";
import Link from "./link";

const ComponentHero = ({ key, data }) => {
  const backgroundImageStyle = data.heroImage?.data?.attributes
    ? {
        backgroundImage: `url(${config.apiBaseUrl}${data.heroImage.data.attributes.url})`,
      }
    : {};
  // const followedByTabs =
  // data.content[0]?.__component === "content.product-accordion";

  // CTA properties
  const cta = data.CTA || {};
  const ctaLabel = cta.label || "";
  const externalURL = cta.url;
  const preferURL = cta.preferURL || false;
  const slug = cta.page?.data?.attributes?.slug || "";

  const ctaHref = preferURL && externalURL ? externalURL : slug;
  const isExternal = preferURL && externalURL;

  return (
    <div
      key={`component_${key}`}
      className="component component-hero"
      style={backgroundImageStyle}
      //   {...(followedByTabs && { "followed-by-tabs": "true" })}
    >
      <h1>{data.title}</h1>
      <div>
        <p>{data.tagline}</p>
        {ctaLabel && (
          <Link href={ctaHref} isExternal={isExternal} content={ctaLabel} />
        )}
      </div>
    </div>
  );
};

export default ComponentHero;
