import { React } from "react";
import { NavLink } from "react-router-dom";

const NavFooter = ({ options }) => {
  function NavLinks(url, title) {
    const itemUrl = new URL(url);
    const relativeUrl = itemUrl.pathname;
    return (
      <NavLink to={relativeUrl} className="nav-link">
        {title}
      </NavLink>
    );
  }

  const contact = options.footer_block_contact || {};
  const block2 = options.footer_block_2 || { links: [] };
  const block3 = options.footer_block_3 || { links: [] };
  const block4 = options.footer_block_4 || { links: [] };

  const hasData =
    contact ||
    block2.links.length ||
    block3.links.length ||
    block4.links.length;

  return (
    <footer>
      <span className="logo">metrilus</span>
      {hasData && (
        <>
          {contact.length > 0 && (
            <section className="footer-links__contact">
              <h3>{contact.footer_block_headline || "Contact Us"}</h3>
              <address>
                {contact.street || "Street"} <br />
                {contact.plz || "Postal Code"} {contact.city || "City"} <br />
                {contact.country || "Country"} <br />
                <a href={`tel:${contact.telephone || ""}`}>
                  {contact.telephone || "Telephone"}
                </a>{" "}
                <br />
                {contact.press &&
                  NavLinks(contact.press.url, contact.press.title)}
              </address>
            </section>
          )}
          {block2.links.length > 0 && (
            <section className="footer-links__block-2">
              <h3>{block2.footer_block_headline || "Block 2"}</h3>
              <ul>
                {block2.links.map((item, index) => (
                  <li key={index}>
                    {NavLinks(item.link.url, item.link.title)}
                  </li>
                ))}
              </ul>
            </section>
          )}
          {block3.links.length > 0 && (
            <section className="footer-links__block-3">
              <h3>{block3.footer_block_headline || "Block 3"}</h3>
              <ul>
                {block3.links.map((item, index) => (
                  <li key={index}>
                    {NavLinks(item.link.url, item.link.title)}
                  </li>
                ))}
              </ul>
            </section>
          )}
          {block4.links.length > 0 && (
            <section className="footer-links__block-4">
              <h3>{block4.footer_block_headline || "Block 4"}</h3>
              <ul>
                {block4.links.map((item, index) => (
                  <li key={index}>
                    {NavLinks(item.link.url, item.link.title)}
                  </li>
                ))}
              </ul>
            </section>
          )}
          {contact.social_link_1 &&
            contact.social_link_2 &&
            contact.social_link_3 && (
              <ul className="footer-links__social">
                <li>
                  <a href={contact.social_link_1.url}>
                    {contact.social_link_1.title}
                  </a>
                </li>
                <li>
                  <a href={contact.social_link_2.url}>
                    {contact.social_link_2.title}
                  </a>
                </li>
                <li>
                  <a href={contact.social_link_3.url}>
                    {contact.social_link_3.title}
                  </a>
                </li>
              </ul>
            )}
        </>
      )}
      <div className="imprint">Metrilus GmbH</div>
    </footer>
  );
};

export default NavFooter;
