import React from "react";

const Link = ({ href, isExternal = false, className = "", content }) => {
  const classes = `link--cta ${className}`;
  const externalAttrs = isExternal
    ? { target: "_blank", rel: "noopener noreferrer" }
    : {};

  return (
    <a href={href} className={classes} {...externalAttrs}>
      {content}
    </a>
  );
};

export default Link;
