// import ComponentHeadline from "./headline.js";
// import ComponentIntro from "./intro.js";
// import ComponentMedia from "./media.js";
// import ComponentTeaser from "./teaser.js";
import ComponentAccordion from "./accordion.js";
import ComponentParagraph from "./paragraph.js";
import ComponentParagraphImage from "./paragraph-image.js";
import ComponentQuote from "./quote.js";
import ComponentDescriptionList from "./description-list.js";
import ComponentBenefitTeaser from "./benefit-teaser.js";
import ComponentProductTabs from "./product-tabs.js";

const Components = ({ content }) => {
  return (
    <>
      {content.content.map((component, index) => {
        if (!component?.__component) return null;
        switch (component.__component) {
          case "content.product-accordion":
            return <ComponentProductTabs data={component} key={index} />;
          case "content.paragraph":
            return <ComponentParagraph data={component} key={index} />;
          case "content.content50":
            return <ComponentParagraphImage data={component} key={index} />;
          //   case "content.col50multi":
          //     return <ComponentParagraphImage data={component} key={index} />
          case "content.faq":
            return <ComponentAccordion data={component} key={index} />;
          case "content.benefit":
            return <ComponentBenefitTeaser data={component} key={index} />;
          case "content.testimonial":
            return <ComponentQuote data={component} key={index} />;
          case "content.prop-group":
            return <ComponentDescriptionList data={component} key={index} />;
          case "content.contentmulti":
            return <ComponentDescriptionList data={component} key={index} />;
          default:
            return null;
        }
      })}
    </>
  );
};

export default Components;
