import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import fetchContentBySlug from "../api";
import ComponentHero from "../components/hero.js";
import Components from "../components/index.js";

const Home = () => {
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const routeSlug = window.location.pathname.split("/").pop();
    const type = "pages";

    fetchContentBySlug(type, routeSlug)
      .then((data) => {
        setContent(data.data[0].attributes);
        // console.log("components component", data.data[0].attributes);
        setLoading(false);
      })
      .catch(() => {
        navigate("/404");
        setLoading(false);
      });
  }, [navigate]);

  return (
    <>
      {loading ? (
        <div>Ladevorgang...</div>
      ) : (
        <>
          {content.title && <ComponentHero data={content} />}
          {content.content && <Components content={content} />}
        </>
      )}
    </>
  );
};

export default Home;
