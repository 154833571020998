import config from "../config";
import Link from "./link";

const ComponentParagraphImage = ({ key, data }) => {
  if (!data) return null;

  // Content properties
  const content = data.content || {};
  const tagline = content.tagline || "";
  const header = content.header || "";
  const text = content.text || "";

  // Image properties
  const image = content.image?.data?.attributes || {};
  const imageUrl = image.url || "";
  const imageAlt = image.alternativeText || "";
  const imageCaption = image.caption || "";

  // Layout properties
  const layout = data.layout || {};
  const reverse = layout.reverse || false;
  const layoutType = layout.layout || "";

  // CTA properties
  const cta = data.CTA || {};
  const ctaLabel = cta.label || "";
  const externalURL = cta.url;
  const preferURL = cta.preferURL || false;
  const slug = cta.page?.data?.attributes?.slug || "";

  const ctaHref = preferURL && externalURL ? externalURL : slug;
  const isExternal = preferURL && externalURL;

  return (
    <div
      key={`component_${key}`}
      className="component component-paragraph-image"
      reversed={reverse}
      layout={layoutType}
    >
      <div className="component-paragraph-image_content">
        <div className="meta-headline">{tagline}</div>
        <h2>{header}</h2>
        <p>{text}</p>
        {ctaLabel && (
          <Link href={ctaHref} isExternal={isExternal} content={ctaLabel} />
        )}
      </div>
      <figure>
        <img src={`${config.apiBaseUrl}${imageUrl}`} alt={imageAlt} />
        {imageCaption && <figcaption>{imageCaption}</figcaption>}
      </figure>
    </div>
  );
};

export default ComponentParagraphImage;
