import { React, useState } from "react";
import { NavLink } from "react-router-dom";
import config from "../config";

const NavHeader = ({ options }) => {
  console.log("nav options", options);

  const [toggle, setToggle] = useState(true);
  const [content, setContent] = useState([]);

  //   useEffect(() => {
  //     fetchNavLinks()
  //       .then((data) => {
  //         setContent(data.header_links);
  //       })
  //       .catch(() => console.warn("no navigation available"));
  //   }, []);

  function NavLinks(url, title) {
    const itemUrl = new URL(url);
    const relativeUrl = itemUrl.pathname;
    return (
      <NavLink to={relativeUrl} className="nav-link">
        {title}
      </NavLink>
    );
  }

  return (
    <header>
      <span className="logo">metrilus</span>
      <button
        type="button"
        className="menu-btn"
        aria-expanded={!toggle}
        aria-controls="menu"
        onClick={() => setToggle(!toggle)}
      >
        <span className="menu-btn__label">Menu</span>
        <span className="menu-btn__icon">
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </span>
      </button>
      <nav id="menu" className={!toggle ? "expanded" : ""} role="navigation">
        <ul>
          {options?.data?.attributes?.pages?.data.map((item, index) => (
            <li key={`nav-link_${index}`}>
              {NavLinks(
                `${window.location.origin}/${item?.attributes?.slug}`,
                item?.attributes?.internalTitle
              )}
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
};

export default NavHeader;
